import Config from '../../../config/config';
import {
  filterAppsWithReadPermission
} from '../../core-utils/Helper/helper';
import {
  MOCK_APPS_ACCESSED_WIDGET,
  MOCK_CATEGORY_VIOLATIONS_WIDGET,
} from '../../core-utils/test-constants';
import axios from '../axios';
import UserGroupService from '../user-group-service/UserGroupService';

export interface IAttributes {
  disabled?: boolean | string | null;
  expired?: string | null;
  'access-window-start'?: string | null;
  'access-window-end'?: string | null;
  'valid-from'?: string | null;
  'valid-until'?: string | null;
  timezone?: string | null;
  'guac-full-name': string;
  'guac-organization'?: string | null;
  'guac-organizational-role': string | null;
  jobRole?: string;
  'guac-email-address': string;
  'guac-totp-key-confirmed'?: string | null;
  'guac-totp-key-secret'?: string | null;
  'sonet-source'?: string | null;
  description?: string | null;
}
export interface IListUserResponse {
  id?: string;
  username: string;
  password?: string;
  attributes: IAttributes;
  lastActive?: number;
  groups: string[];
}

export interface IListUsersResponse {
  [key: string]: IListUserResponse;
}
export interface ICreateUserRequest {
  username: string;
  password: string;
  attributes: IAttributes;
}

export interface IUpdateUserRequest {
  password: string;
  attributes: IAttributes;
}

export interface IListUsersPoliciesResponse {
  id: string;
  policyName: string;
  createdBy: string;
  userGroups: string[];
  applications: string[];
  lastPolicyHitTime: string;
  status: 'active' | 'inactive';
}

const isDashboardAPIValid = () => {
  return Config.DASHBOARD_API_URL ? true : false;
};

const UserService = {
  addNewUser: async (userDetails: ICreateUserRequest) => {
    try {
      const addUserResponse = await axios.post(
        `${Config.BACKEND_BASE_URL}api/session/data/postgresql/users`,
        userDetails,
      );
      return addUserResponse.data;
    } catch (error) {
      throw error;
    }
  },
  updateUser: async (username: string, userDetails: any) => {
    try {
      const updateUserResponse = await axios.put(
        `${
          Config.BACKEND_BASE_URL
        }api/session/data/postgresql/users/${encodeURIComponent(username)}`,
        userDetails,
      );
      return updateUserResponse.data;
    } catch (error) {
      console.error(error);
      throw error;
    }
  },
  updateUserPassword: async (username: string, newPassword: string) => {
    try {
      await axios.put(
        `${
          Config.BACKEND_BASE_URL
        }api/session/data/postgresql/users/${encodeURIComponent(
          username,
        )}/password`,
        {
          newPassword,
        },
      );
    } catch (error) {
      return error;
    }
  },
  updateUserInformationForEndUser: async (
    username: string,
    userDetails: any,
  ) => {
    try {
      const updateUserResponse = await axios.put(
        `${
          Config.BACKEND_BASE_URL
        }api/session/data/postgresql/users/${encodeURIComponent(username)}`,
        userDetails,
      );
      return updateUserResponse.data;
    } catch (error) {
      console.error(error);
    }
  },
  updatePasswordForEndUser: async (
    username: string,
    newPassword: string,
    oldPassword: string,
  ) => {
    try {
      await axios.put(
        `${
          Config.BACKEND_BASE_URL
        }api/session/data/postgresql/users/${encodeURIComponent(
          username,
        )}/password`,
        {
          newPassword,
          oldPassword,
        },
      );
    } catch (error) {
      return error;
    }
  },
  listUsers: async () => {
    try {
      const listUsersResponse = await axios.get(
        `${Config.BACKEND_BASE_URL}api/session/data/postgresql/users`,
      );
      return listUsersResponse.data as IListUsersResponse;
    } catch (error) {
      return error;
    }
  },
  getUserById: async (userId: number | string) => {
    try {
      const userByid = await axios.get(
        `${
          Config.BACKEND_BASE_URL
        }api/session/data/postgresql/users/${encodeURIComponent(userId)}`,
      );
      return userByid.data;
    } catch (error) {
      return error;
    }
  },
  getSSOStatus: async (): Promise<
    | {
        completedSSO?: string;
        statusCode?: string;
        statusType?: string;
      }
    | any
  > => {
    try {
      const ssoStatus = await axios.get(
        `${Config.BACKEND_BASE_URL}api/sonet/session/sso/status`,
      );
      return {
        completedSSO: ssoStatus?.data?.completedSSO,
        statusCode: ssoStatus?.data?.statusCode,
        statusType:
          ssoStatus?.data?.type === 'PERMISSION_DENIED' ? 'INVALID_TOKEN' : '',
      };
    } catch (error) {
      return error;
    }
  },
  getTotpStatus: async () => {
    try {
      const totpStatus = await axios.get(
        `${Config.BACKEND_BASE_URL}api/sonet/session/totp/status`,
      );
      return totpStatus.data.completedTotpAuth;
    } catch (error) {
      return error;
    }
  },
  setTotpStatus: async () => {
    try {
      const totpStatus = await axios.put(
        `${Config.BACKEND_BASE_URL}api/sonet/session/totp`,
      );
      return totpStatus.data;
    } catch (error) {
      return error;
    }
  },
  getAppsOfUser: async (username: number | string) => {
    try {
      const appsByUserId = await axios.get(
        `${
          Config.BACKEND_BASE_URL
        }api/session/data/postgresql/users/${encodeURIComponent(
          username,
        )}/permissions`,
      );
      return filterAppsWithReadPermission(appsByUserId.data);
    } catch (error) {
      return error;
    }
  },
  getEffectiveAppsOfUser: async (username: number | string) => {
    try {
      const appsByUserId = await axios.get(
        `${
          Config.BACKEND_BASE_URL
        }api/session/data/postgresql/users/${encodeURIComponent(
          username,
        )}/effectivePermissions`,
      );
      return filterAppsWithReadPermission(appsByUserId.data);
    } catch (error) {
      return error;
    }
  },
  getAppsOfSelf: async () => {
    try {
      const appsByUserId = await axios.get(
        `${Config.BACKEND_BASE_URL}api/session/data/postgresql/self/effectivePermissions`,
      );
      return filterAppsWithReadPermission(appsByUserId.data);
    } catch (error) {
      return error;
    }
  },
  addGroupsToUser: async (username: string, requestBody: any) => {
    try {
      await axios.patch(
        `${
          Config.BACKEND_BASE_URL
        }api/session/data/postgresql/users/${encodeURIComponent(
          username,
        )}/userGroups`,
        requestBody,
      );
    } catch (error) {
      console.error(error);
    }
  },

  addPermissionToUser: async (username: string, requestBody: any) => {
    try {
      await axios.patch(
        `${
          Config.BACKEND_BASE_URL
        }api/session/data/postgresql/users/${encodeURIComponent(
          username,
        )}/permissions`,
        requestBody,
      );
    } catch (error) {
      console.error(error);
    }
  },
  removeGroupsFromUser: async (username: string, requestBody: any) => {
    try {
      await axios.patch(
        `${
          Config.BACKEND_BASE_URL
        }api/session/data/postgresql/users/${encodeURIComponent(
          username,
        )}/userGroups`,
        requestBody,
      );
    } catch (error) {
      console.error(error);
    }
  },
  deleteUser: async (userId: number | string) => {
    try {
      const deleteUserResponse = await axios.delete(
        `${Config.BACKEND_BASE_URL}api/session/data/postgresql/users/${userId}`,
      );
      return deleteUserResponse.data;
    } catch (error) {
      return error;
    }
  },
  addAppsToUser: async (username: string, requestBody: any) => {
    try {
      await axios.patch(
        `${
          Config.BACKEND_BASE_URL
        }api/session/data/postgresql/users/${encodeURIComponent(
          username,
        )}/permissions`,
        requestBody,
      );
    } catch (error) {
      console.error(error);
    }
  },
  removeAppsFromUser: async (username: string, requestBody: any) => {
    try {
      await axios.patch(
        `${
          Config.BACKEND_BASE_URL
        }api/session/data/postgresql/users/${encodeURIComponent(
          username,
        )}/permissions`,
        requestBody,
      );
    } catch (error) {
      console.error(error);
    }
  },
  addPolicyToUser: async (policyId: string | number, requestBody: any) => {
    try {
      await axios.patch(
        `${Config.BACKEND_BASE_URL}api/session/data/postgresql/policyDef/${policyId}/memberUsers`,
        requestBody,
      );
    } catch (error) {
      console.error(error);
    }
  },
  removePolicyFromUser: async (policyId: string | number, requestBody: any) => {
    try {
      await axios.patch(
        `${Config.BACKEND_BASE_URL}api/session/data/postgresql/policyDef/${policyId}/memberUsers`,
        requestBody,
      );
    } catch (error) {
      console.error(error);
    }
  },
  getPoliciesOfUser: async (username: string) => {
    // TODO:
    // return ['13', '7', '8', '9'];
    try {
      const allPolicies = await axios.get(
        `${
          Config.BACKEND_BASE_URL
        }api/session/data/postgresql/users/${encodeURIComponent(
          username,
        )}/policies`,
      );
      return allPolicies.data;
    } catch (error) {
      console.error(error);
    }
  },
  getAppsAccessedByUser: async (
    startDate: string,
    endDate: string,
    username: string,
  ) => {
    // TODO: remove return once the api is functional
    // return MOCK_APPS_ACCESSED_WIDGET;
    try {
      if (!isDashboardAPIValid()) {
        return MOCK_APPS_ACCESSED_WIDGET;
      }
      const appsAccessed = await axios.get(
        `${Config.DASHBOARD_API_URL}users/${encodeURIComponent(
          username,
        )}/AppsAccessedCount`,
        { params: { startDate: startDate, endDate: endDate } },
      );
      return appsAccessed.data.slice(0, 5);
    } catch (error) {
      console.error(error);
      return [];
    }
  },
  getCategoryViolationsByUser: async (
    startDate: string,
    endDate: string,
    username: string,
  ) => {
    // return MOCK_CATEGORY_VIOLATIONS_WIDGET;
    try {
      if (!isDashboardAPIValid()) {
        return MOCK_CATEGORY_VIOLATIONS_WIDGET;
      }
      const categoryViolations = await axios.get(
        `${Config.DASHBOARD_API_URL}users/${encodeURIComponent(
          username,
        )}/CategoryViolations`,
        { params: { startDate: startDate, endDate: endDate } },
      );
      return categoryViolations.data.slice(0, 10);
    } catch (error) {
      console.error(error);
      return [];
    }
  },
  getAvgDailyAppsCount: async (
    startDate: string,
    endDate: string,
    username: string,
  ) => {
    try {
      const avgDailyAppsCount = await axios.get(
        `${Config.DASHBOARD_API_URL}users/${encodeURIComponent(
          username,
        )}/AverageDailyApplications`,
        { params: { startDate: startDate, endDate: endDate } },
      );
      return avgDailyAppsCount.data;
    } catch (error) {
      console.error(error);
      return 0;
    }
  },
  getPolicyViolationsCount: async (
    startDate: string,
    endDate: string,
    username: string,
  ) => {
    try {
      const policyViolationsCount = await axios.get(
        `${Config.DASHBOARD_API_URL}users/${encodeURIComponent(
          username,
        )}/PolicyViolations`,
        { params: { startDate: startDate, endDate: endDate } },
      );
      return policyViolationsCount.data;
    } catch (error) {
      console.error(error);
      return 0;
    }
  },
  getUserLogins: async (
    startDate: string,
    endDate: string,
    username: string,
  ) => {
    try {
      const userLoginsCount = await axios.get(
        `${Config.DASHBOARD_API_URL}users/${encodeURIComponent(
          username,
        )}/NumberOfLogins`,
        { params: { startDate: startDate, endDate: endDate } },
      );
      console.log(userLoginsCount);
      return userLoginsCount.data;
    } catch (error) {
      console.error(error);
      return 0;
    }
  },
  getTotalSessions: async (
    startDate: string,
    endDate: string,
    username: string,
  ) => {
    try {
      const totalSessionsCount = await axios.get(
        `${Config.DASHBOARD_API_URL}users/${encodeURIComponent(
          username,
        )}/TotalSessions`,
        { params: { startDate: startDate, endDate: endDate } },
      );
      return totalSessionsCount.data;
    } catch (error) {
      console.error(error);
      return 0;
    }
  },
  getTotalSessionsDuration: async (
    startDate: string,
    endDate: string,
    username: string,
  ) => {
    try {
      const totalSessionsDurationCount = await axios.get(
        `${Config.DASHBOARD_API_URL}users/${encodeURIComponent(
          username,
        )}/TotalSessionDuration`,
        { params: { startDate: startDate, endDate: endDate } },
      );
      return totalSessionsDurationCount.data;
    } catch (error) {
      console.error(error);
      return 0;
    }
  },
  getAverageSessionsDuration: async (
    startDate: string,
    endDate: string,
    username: string,
  ) => {
    try {
      const averageSessionsDuration = await axios.get(
        `${Config.DASHBOARD_API_URL}users/${encodeURIComponent(
          username,
        )}/AverageSessionDuration`,
        { params: { startDate: startDate, endDate: endDate } },
      );
      return averageSessionsDuration.data;
    } catch (error) {
      console.error(error);
      return 0;
    }
  },
  getUserLastLoginTime: async (username: string) => {
    //TODO: remove this return once we have apis
    return '2020-05-12T17:35:00';
  },
  getUserGroupsAppsOfUser: async (username: string) => {
    try {
      const userGroupsApps: any = [];
      const userGroupsOfUser = await UserGroupService.getUserGroupsForUser(
        username,
      );
      const dict: any = {};
      const temp = userGroupsOfUser.map(async (id: string | number) => {
        const searchAppsOfUserGroup = await UserGroupService.getAppsOfUserGroup(
          id,
        );
        Object.keys(searchAppsOfUserGroup?.connectionPermissions)?.forEach(
          (appId: string) => {
            if (!Object.keys(dict).includes(appId)) {
              dict[appId] = [id];
            } else {
              dict[appId].push(id);
            }
          },
        );
      });
      await Promise.all(temp);
      return dict;
    } catch (error) {
      return error;
    }
  },
  isUserFirstLogin: async (username: string) => {
    try {
      const firstTimeLogin = await axios.get(
        `${Config.DASHBOARD_API_URL}firstTimeLogin/${encodeURIComponent(
          username,
        )}`,
      );
      return firstTimeLogin.data;
    } catch (error) {
      return false;
    }
  },
  setUserFirstTimeLoginStatus: async (username: string, status: boolean) => {
    try {
      const firstTimeLogin = await axios.put(
        `${Config.DASHBOARD_API_URL}firstTimeLogin/${encodeURIComponent(
          username,
        )}`,
        { status: status },
      );
      return firstTimeLogin.data;
    } catch (error) {
      return error;
    }
  },
  sendUserOnboardingMail: async (
    username: string,
    email: string,
    password: string,
    fullName: string,
    onboardingUrl: string,
  ) => {
    try {
      await axios.post(`${Config.DASHBOARD_API_URL}notifyUserAdd`, {
        username,
        email,
        password,
        fullName,
        onboardingUrl,
      });
    } catch (error) {
      return error;
    }
  },
  sendUserPasswordChangeMail: async (
    username: string,
    email: string,
    fullName: string,
    onboardingUrl: string,
    password: string | undefined = undefined,
  ) => {
    try {
      await axios.post(`${Config.DASHBOARD_API_URL}notifyUserPasswordChange`, {
        username,
        email,
        password,
        fullName,
        onboardingUrl,
      });
    } catch (error) {
      return error;
    }
  },
  isAdminFirstLogin: async (username: string) => {
    try {
      // if (!username.toLowerCase().startsWith('admin@')) {
      //   return false;
      // }
      const firstTimeLogin = await axios.get(
        `${Config.DASHBOARD_API_URL}firstTimeLogin/${encodeURIComponent(
          username,
        )}`,
      );
      return firstTimeLogin.data;
    } catch (error) {
      return false;
    }
  },
  setAdminFirstTimeLoginStatus: async (username: string, status: boolean) => {
    try {
      const firstTimeLogin = await axios.put(
        `${Config.DASHBOARD_API_URL}firstTimeLogin/${encodeURIComponent(
          username,
        )}`,
        { status: status },
      );
      return firstTimeLogin.data;
    } catch (error) {
      return error;
    }
  },
  sendOnboardingMail: async (
    username: string,
    email: string,
    password: string,
    fullName: string,
    onboardingUrl: string,
  ) => {
    try {
      await axios.post(`${Config.DASHBOARD_API_URL}notifyUserAdd`, {
        username,
        email,
        password,
        fullName,
        onboardingUrl,
      });
    } catch (error) {
      return error;
    }
  },
  getTotpLogo: async (username: string) => {
    try {
      const logo = await axios.get(
        `${Config.DASHBOARD_API_URL}totp/${encodeURIComponent(
          username,
        )}/qrCode`,
      );
      return logo.data;
    } catch (error) {
      return error;
    }
  },
  getTotpSecretKey: async (username: string) => {
    try {
      const secretKey = await axios.get(
        `${Config.DASHBOARD_API_URL}totp/${encodeURIComponent(
          username,
        )}/secretKey`,
      );
      return secretKey.data;
    } catch (error) {
      return error;
    }
  },
  verifyTotpEnrolment: async (username: string, code: string) => {
    try {
      const authenticated = await axios.post(
        `${Config.DASHBOARD_API_URL}totp/${encodeURIComponent(
          username,
        )}/verifyEnrolment`,
        null,
        {
          params: { code },
        },
      );
      return authenticated.data;
    } catch (error) {
      return error;
    }
  },
  authenticateTotp: async (username: string, code: string) => {
    try {
      const authenticated = await axios.post(
        `${Config.DASHBOARD_API_URL}totp/${encodeURIComponent(
          username,
        )}/authenticate`,
        null,
        {
          params: { code },
        },
      );
      return true;  //authenticated.data;
    } catch (error) {
      return true; //error;
    }
  },
};

export default UserService;
